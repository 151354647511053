:root {
  --header-cell-bg-color: #f6f6f9;
}

.xform-cell {
  color: #3b2ed0;
  background: #f5f4fe;
}

.column-group-header .MuiDataGrid-columnHeaderTitleContainerContent {
  flex: 1;
  text-align: left;
}

.MuiChip-label {
  flex: 1;
}

.MuiDataGrid-columnHeader {
  background-color: var(--header-cell-bg-color);
}

.row-number-cell {
  font-weight: 600 !important;
  text-align: center !important;
  background-color: var(--header-cell-bg-color);
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  font-weight: 600;
}

.MuiInputBase-inputSizeSmall,
.MuiToggleButton-sizeSmall,
.MuiButton-sizeSmall {
  /* padding: 4px 24px !important; */
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1;
}


.expand-button {
  width: 64px; /* Adjust to match the button's width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
}

/* Styles for the Sidebar container */
.sidebar-container {
  /*flex: 1;*/
  /*min-width:0;*/
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-button {
  position: absolute;
  margin: 0.5em;
  z-index: 100;
}

.sidebar.collapsed {
  width: 0;
}

.conversation-list-container {
  margin-top: 0.35em;
}

/* Styles for the MainPage container */

.main-content {
  flex-grow: 1;
  height: calc(100vh - 64px);
  overflow-y: auto;
}


textarea:focus {
  outline: none;
}

textarea:focus-visible {
  outline: none;
}

.chat-pg-instructions {
  display: flex;
  flex: 0 0;
}

.shadow-xs {
  --tw-shadow: 0 0 15px rgba(0, 0, 0, .1);
  box-shadow: var(--tw-shadow);
}

.text-input-with-header .subheading {
  padding: 22px 16px 12px 0px;
}

.custom-select {
  -webkit-appearance: none; /* Remove default style */
  -moz-appearance: none; /* For Firefox */
  appearance: none;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"%3E%3Cpath d="M5 7l5 5 5-5" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.25em 1.25em;
  border: 1px solid currentColor;
  padding-right: 2rem;
}

.dark .dark\:custom-select {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"%3E%3Cpath d="M5 7l5 5 5-5" stroke="%23cbd5e1" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
}

/* Light Mode Hover */
.custom-select:hover {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"%3E%3Cpath d="M5 7l5 5 5-5" stroke="%23a0aec0" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
}

/* Dark Mode Hover */
.dark .custom-select:hover {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"%3E%3Cpath d="M5 7l5 5 5-5" stroke="%23ffffff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
}

.dark\:tooltip-arrow {
  fill: white;
}

.sidebar-collapsed-margin {
  margin-left: 3em;
}

.sidebar-expanded-margin {
  margin-left: 0.2em;
}

.streaming-dot {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.subheading {
  color: #202123;
  color: var(--gray-900);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .08em;
  line-height: 16px;
  text-transform: uppercase;
}


.text-input-with-header .text-input-header-wrapper {
  width: 85%;
}

.text-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid var(--input-border);
  border-radius: 3px;
  box-sizing: border-box;
  color: var(--gray-800);
  display: inline-block;
  font-family: var(--sans-serif);
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  vertical-align: top;
  width: 200px;
}

.text-input-with-header .text-input-header-wrapper .text-input-header-buffer {
  border: none;
  height: 100%;
  overflow: auto;
  padding: 0 14px 10px;
  width: 100%;
}

.chat-pg-instructions textarea {
  resize: none;
}

.regenerate-button:hover svg path {
  stroke: black;
}

.dark .regenerate-button:hover svg path {
  stroke: white;
}

.text-input-full {
  display: block;
  width: 100%;
}

.scroll-to-bottom-button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-20px);
  bottom: 100%;
  z-index: 1000;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent; /* Thumb and track color */
}

code, pre {
  font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important
}

code[class=language-plaintext] {
  white-space: pre-line
}

pre[class*=language-] {
  border-radius: .3em;
  overflow: auto
}

.focus-within\:z-10[focus-within] {
  z-index: 10
}

.focus-within\:z-10:focus-within {
  z-index: 10
}

.focus-within\:border-indigo-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

.focus-within\:ring-1[focus-within] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-indigo-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(64, 65, 79, var(--tw-border-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 236, 241, var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 217, 227, var(--tw-bg-opacity))
}

.hover\:bg-\[\#2A2B32\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 43, 50, var(--tw-bg-opacity))
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.hover\:bg-gray-500\/10:hover {
  background-color: hsla(240, 9%, 59%, .1)
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 247, 248, var(--tw-bg-opacity))
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 197, 210, var(--tw-bg-opacity))
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(210, 244, 211, var(--tw-bg-opacity))
}

.hover\:pr-4:hover {
  padding-right: 1rem
}

.hover\:pr-14:hover {
  padding-right: 3.5rem
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(142, 142, 160, var(--tw-text-opacity))
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(64, 65, 79, var(--tw-text-opacity))
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 53, 65, var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(32, 33, 35, var(--tw-text-opacity))
}

.hover\:text-black\/70:hover {
  color: rgba(0, 0, 0, .7)
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.hover\:text-black\/50:hover {
  color: rgba(0, 0, 0, .5)
}

.hover\:opacity-80:hover {
  opacity: .8
}

.hover\:opacity-75:hover {
  opacity: .75
}

.focus\:z-10:focus {
  z-index: 10
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 163, 127, var(--tw-border-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-0:focus,
.focus\:ring-2:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(25, 195, 125, var(--tw-ring-opacity))
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 163, 127, var(--tw-ring-opacity))
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 142, 160, var(--tw-ring-opacity))
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px
}

.focus-visible\:ring-0.focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-visible\:ring-0:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.disabled\:bottom-0\.5:disabled {
  bottom: .125rem
}

.disabled\:bottom-0:disabled {
  bottom: 0
}

.disabled\:cursor-auto:disabled {
  cursor: auto
}

.disabled\:text-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgba(197, 197, 210, var(--tw-text-opacity))
}

.disabled\:opacity-40:disabled {
  opacity: .4
}

.disabled\:opacity-20:disabled {
  opacity: .2
}

.disabled\:hover\:bg-transparent:hover:disabled {
  background-color: transparent
}

.disabled\:hover\:bg-gray-900:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 33, 35, var(--tw-bg-opacity))
}

.group:hover .group-hover\:visible {
  visibility: visible
}

.group:hover .group-hover\:from-\[\#2A2B32\] {
  --tw-gradient-from: #2a2b32;
  --tw-gradient-to: rgba(42, 43, 50, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(142, 142, 160, var(--tw-text-opacity))
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5
}

.ui-open\:bg-gray-800[data-headlessui-state~=open] {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

:where([data-headlessui-state~=open]) .ui-open\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.dark .dark\:prose-invert {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders)
}

.dark .dark\:border-gray-900\/50 {
  border-color: rgba(32, 33, 35, .5)
}

.dark .dark\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(197, 197, 210, var(--tw-border-opacity))
}

.dark .dark\:border-black\/20 {
  border-color: rgba(0, 0, 0, .2)
}

.dark .dark\:border-white\/20 {
  border-color: hsla(0, 0%, 100%, .2)
}

.dark .dark\:border-white\/30 {
  border-color: hsla(0, 0%, 100%, .3)
}

.dark .dark\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(64, 65, 79, var(--tw-border-opacity))
}

.dark .dark\:border-white\/10 {
  border-color: hsla(0, 0%, 100%, .1)
}

.dark .dark\:border-black\/10 {
  border-color: rgba(0, 0, 0, .1)
}

.dark .dark\:bg-\[\#444654\] {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 70, 84, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-800\/90 {
  background-color: rgba(52, 53, 65, .9)
}

.dark .dark\:bg-gray-850 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 38 52 / var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity))
}

.dark .dark\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.dark .dark\:bg-transparent {
  background-color: transparent
}

.dark .dark\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(86, 88, 105, var(--tw-bg-opacity))
}

.dark .dark\:bg-white\/10 {
  background-color: hsla(0, 0%, 100%, .1)
}

.dark .dark\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark .dark\:bg-white\/5 {
  background-color: hsla(0, 0%, 100%, .05)
}

.dark .dark\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

.dark .dark\:bg-\[\#272832\] {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 40, 50, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(142, 142, 160, var(--tw-bg-opacity))
}

.dark .dark\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(236, 236, 241, var(--tw-text-opacity))
}

.dark .dark\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 190, var(--tw-text-opacity))
}

.dark .dark\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(197, 197, 210, var(--tw-text-opacity))
}

.dark .dark\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 227, var(--tw-text-opacity))
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.dark .dark\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

.dark .dark\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(142, 142, 160, var(--tw-text-opacity))
}

.dark .dark\:text-white\/50 {
  color: hsla(0, 0%, 100%, .5)
}

.dark .dark\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(86, 88, 105, var(--tw-text-opacity))
}

.dark .dark\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(32, 33, 35, var(--tw-text-opacity))
}

.dark .dark\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

.dark .dark\:text-white\/70 {
  color: hsla(0, 0%, 100%, .7)
}

.dark .dark\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.dark .dark\:opacity-100 {
  opacity: 1
}

.dark .dark\:shadow-\[0_0_15px_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
  --tw-shadow: 0 0 15px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 0 15px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.dark .dark\:ring-white\/20 {
  --tw-ring-color: hsla(0, 0%, 100%, .2)
}

.dark .dark\:ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(86, 88, 105, var(--tw-ring-opacity))
}

.dark .dark\:last\:border-0:last-child {
  border-width: 0
}

.dark .dark\:hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-850:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(42, 43, 50, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 33, 35, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 217, 227, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-500\/10:hover {
  background-color: hsla(240, 9%, 59%, .1)
}

.dark .dark\:hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 227, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-white\/50:hover {
  color: hsla(0, 0%, 100%, .5)
}

.dark .dark\:focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.dark .dark\:focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.dark .dark\:disabled\:text-gray-400:disabled,
.dark .enabled\:dark\:hover\:text-gray-400:hover:enabled {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 190, var(--tw-text-opacity))
}

.dark .dark\:disabled\:hover\:bg-transparent:hover:disabled {
  background-color: transparent
}

.dark .dark\:disabled\:hover\:bg-white:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark .disabled\:dark\:hover\:text-gray-400:hover:disabled {
  --tw-text-opacity: 1;
  color: rgba(172, 172, 190, var(--tw-text-opacity))
}

.dark .dark\:from-gray-800 {
  --tw-gradient-from: #343541 var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(52, 53, 65, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.dark .dark\:from-gray-900 {
  --tw-gradient-from: rgb(17 24 39 / var(--tw-bg-opacity)) var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(32, 33, 35, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.dark .group:hover .dark\:group-hover\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.dark .group:hover .dark\:group-hover\:from-\[\#2A2B32\] {
  --tw-gradient-from: #2a2b32 var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(42, 43, 50, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.dark .group\/option:hover .dark\:group-hover\/option\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(236, 236, 241, var(--tw-text-opacity))
}


@media (min-width: 1280px) {
  .xl\:w-1\/4 {
    width: 25%
  }

  .xl\:w-\[350px\] {
    width: 350px
  }

  .xl\:max-w-3xl {
    max-width: 48rem
  }

  .xl\:max-w-7xl {
    max-width: 80rem
  }

  .xl\:grid-cols-4 {
    grid-template-columns:repeat(4, minmax(0, 1fr))
  }
}

@media (min-width: 1536px) {
  .\32xl\:w-\[400px\] {
    width: 400px
  }
}

code,
pre {
  font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important
}

code[class=language-plaintext] {
  white-space: pre-line
}


pre[class*=language-] {
  border-radius: .3em;
  overflow: auto
}

@media screen and (-ms-high-contrast: active) {
  code[class*=language-],
  pre[class*=language-] {
    background: window;
    color: windowText
  }

  :not(pre) > code[class*=language-],
  pre[class*=language-] {
    background: window
  }
}

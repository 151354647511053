
.prose {
  color: var(--tw-prose-body);
  /*max-width: 65ch*/
}

.prose :where([class~=lead]):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-bottom: 1.2em;
  margin-top: 1.2em
}

.prose :where(a):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline
}

.prose :where(strong):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600
}

.prose :where(a strong):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(blockquote strong):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(thead th strong):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(ol):not(:where([class~=not-prose] *)) {
  list-style-type: decimal;
  padding-left: 1.625em
}

.prose :where(ol[type=A]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-alpha
}

.prose :where(ol[type=a]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-alpha
}

.prose :where(ol[type=A]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-alpha
}

.prose :where(ol[type=a]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-alpha
}

.prose :where(ol[type=I]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-roman
}

.prose :where(ol[type=i]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-roman
}

.prose :where(ol[type=I]):not(:where([class~=not-prose] *)) {
  list-style-type: upper-roman
}

.prose :where(ol[type=i]):not(:where([class~=not-prose] *)) {
  list-style-type: lower-roman
}

.prose :where(ol[type="1"]):not(:where([class~=not-prose] *)) {
  list-style-type: decimal
}

.prose :where(ul):not(:where([class~=not-prose] *)) {
  list-style-type: disc;
  margin-bottom: 1em;
  margin-top: 1em;
  padding-left: 1.625em
}

.prose :where(ol>li):not(:where([class~=not-prose] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400
}

.prose :where(ul>li):not(:where([class~=not-prose] *))::marker {
  color: var(--tw-prose-bullets)
}

.prose :where(hr):not(:where([class~=not-prose] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-bottom: 3em;
  margin-top: 3em
}

.prose :where(blockquote):not(:where([class~=not-prose] *)) {
  border-left-color: var(--tw-prose-quote-borders);
  border-left-width: .25rem;
  color: var(--tw-prose-quotes);
  font-style: italic;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.6em;
  margin-top: 1.6em;
  padding-left: 1em;
  quotes: "\201C" "\201D" "\2018" "\2019"
}

.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose] *)):before {
  content: open-quote
}

.prose :where(blockquote p:last-of-type):not(:where([class~=not-prose] *)):after {
  content: close-quote
}

.prose :where(h1):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-headings);
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.1111111;
  margin-bottom: .8888889em;
  margin-top: 0
}

.prose :where(h1 strong):not(:where([class~=not-prose] *)) {
  color: inherit;
  font-weight: 900
}

.prose :where(h2):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-headings);
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.3333333;
  margin-bottom: 1em;
  margin-top: 2em
}

.prose :where(h2 strong):not(:where([class~=not-prose] *)) {
  color: inherit;
  font-weight: 800
}

.prose :where(h3):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-headings);
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: .6em;
  margin-top: 1.6em
}

.prose :where(h3 strong):not(:where([class~=not-prose] *)) {
  color: inherit;
  font-weight: 700
}

.prose :where(h4):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: .5em;
  margin-top: 1.5em
}

.prose :where(h4 strong):not(:where([class~=not-prose] *)) {
  color: inherit;
  font-weight: 700
}

.prose :where(img):not(:where([class~=not-prose] *)) {
  margin-bottom: 1em;
  margin-top: 1em
}

.prose :where(figure>*):not(:where([class~=not-prose] *)) {
  margin-bottom: 0;
  margin-top: 0
}

.prose :where(figcaption):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-captions);
  font-size: .875em;
  line-height: 1.4285714;
  margin-top: .8571429em
}

.prose :where(code):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600
}

.prose :where(code):not(:where([class~=not-prose] *)):before {
  content: "`"
}

.prose :where(code):not(:where([class~=not-prose] *)):after {
  content: "`"
}

.prose :where(a code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(h1 code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(h2 code):not(:where([class~=not-prose] *)) {
  color: inherit;
  font-size: .875em
}

.prose :where(h3 code):not(:where([class~=not-prose] *)) {
  color: inherit;
  font-size: .9em
}

.prose :where(h4 code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(blockquote code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(thead th code):not(:where([class~=not-prose] *)) {
  color: inherit
}

.prose :where(pre):not(:where([class~=not-prose] *)) {
  background-color: transparent;
  border-radius: .375rem;
  color: currentColor;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.7142857;
  margin: 0;
  overflow-x: auto;
  padding: 0
}

.prose :where(pre code):not(:where([class~=not-prose] *)) {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0
}

.prose :where(pre code):not(:where([class~=not-prose] *)):before {
  content: none
}

.prose :where(pre code):not(:where([class~=not-prose] *)):after {
  content: none
}

.prose :where(table):not(:where([class~=not-prose] *)) {
  font-size: .875em;
  line-height: 1.7142857;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  table-layout: auto;
  text-align: left;
  width: 100%
}

.prose :where(thead):not(:where([class~=not-prose] *)) {
  border-bottom-color: var(--tw-prose-th-borders);
  border-bottom-width: 1px
}

.prose :where(thead th):not(:where([class~=not-prose] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  padding-bottom: .5714286em;
  padding-left: .5714286em;
  padding-right: .5714286em;
  vertical-align: bottom
}

.prose :where(tbody tr):not(:where([class~=not-prose] *)) {
  border-bottom-color: var(--tw-prose-td-borders);
  border-bottom-width: 1px
}

.prose :where(tbody tr:last-child):not(:where([class~=not-prose] *)) {
  border-bottom-width: 0
}

.prose :where(tbody td):not(:where([class~=not-prose] *)) {
  vertical-align: baseline
}

.prose :where(tfoot):not(:where([class~=not-prose] *)) {
  border-top-color: var(--tw-prose-th-borders);
  border-top-width: 1px
}

.prose :where(tfoot td):not(:where([class~=not-prose] *)) {
  vertical-align: top
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgba(0, 0, 0, .5);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.33;
}

.prose :where(p):not(:where([class~=not-prose] *)) {
  margin-bottom: 1em;
  margin-top: 1em
}

.prose :where(video):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.5em;
  margin-top: 2em
}

.prose :where(figure):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.5em;
  margin-top: 2em
}

.prose :where(li):not(:where([class~=not-prose] *)) {
  margin-bottom: .5em;
  margin-top: .5em
}

.prose :where(ol>li):not(:where([class~=not-prose] *)) {
  padding-left: .375em
}

.prose :where(ul>li):not(:where([class~=not-prose] *)) {
  padding-left: .375em
}

.prose :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom: .75em;
  margin-top: .75em
}

.prose :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1em
}

.prose :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1em
}

.prose :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1em
}

.prose :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1em
}

.prose :where(ul ul,ul ol,ol ul,ol ol):not(:where([class~=not-prose] *)) {
  margin-bottom: .75em;
  margin-top: .75em
}

.prose :where(hr+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(h2+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(h3+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(h4+*):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(thead th:first-child):not(:where([class~=not-prose] *)) {
  padding-left: 0
}

.prose :where(thead th:last-child):not(:where([class~=not-prose] *)) {
  padding-right: 0
}

.prose :where(tbody td,tfoot td):not(:where([class~=not-prose] *)) {
  padding: .5714286em
}

.prose :where(tbody td:first-child,tfoot td:first-child):not(:where([class~=not-prose] *)) {
  padding-left: 0
}

.prose :where(tbody td:last-child,tfoot td:last-child):not(:where([class~=not-prose] *)) {
  padding-right: 0
}

.prose :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose-sm :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom: .5714286em;
  margin-top: .5714286em
}

.prose-sm :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.1428571em
}

.prose-sm :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.1428571em
}

.prose-sm :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.1428571em
}

.prose-sm :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.1428571em
}

.prose-sm :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose-sm :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose-base :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom: .75em;
  margin-top: .75em
}

.prose-base :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1em
}

.prose-base :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1em
}

.prose-base :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1em
}

.prose-base :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1em
}

.prose-base :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose-base :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose-lg :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom: .8888889em;
  margin-top: .8888889em
}

.prose-lg :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.3333333em
}

.prose-lg :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.3333333em
}

.prose-lg :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.3333333em
}

.prose-lg :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.3333333em
}

.prose-lg :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose-lg :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose-xl :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom: .8em;
  margin-top: .8em
}

.prose-xl :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.2em
}

.prose-xl :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.2em
}

.prose-xl :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.2em
}

.prose-xl :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.2em
}

.prose-xl :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose-xl :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose-2xl :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom: .8333333em;
  margin-top: .8333333em
}

.prose-2xl :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.3333333em
}

.prose-2xl :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.3333333em
}

.prose-2xl :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 1.3333333em
}

.prose-2xl :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 1.3333333em
}

.prose-2xl :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top: 0
}

.prose-2xl :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom: 0
}

.prose-invert {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders)
}

/* MarkdownBlock.css */
.codeBlockContainer pre {
  white-space: pre-wrap !important; /* Allow the text to wrap */
  word-break: break-word !important;; /* Break the word to prevent overflow */
  overflow-wrap: break-word !important;; /* Ensure long words do not cause overflow */
}